import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/id";
import { id } from "date-fns/locale"; // this is localization for Indonesian Language......
import "../Styles/SelectStation.css";
import { Header, Footer, SelectStationDropDown, Loader } from "../index";
import { Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getSchedule, getStation } from "../../api/ApiUtils";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";

moment.locale("id");

const StationSelection = () => {
  const classes = useStyle();
  const header = "Cari Jadwal Kereta";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // STATE'S TO HANDLE THE SLIDERS TO SELECT THE STATION NAMES
  const [startStation, setStartStation] = useState("");
  const [openStartStationDropDown, setOpenStartStationDropDown] =
    useState(false);
  const [stopStation, setStopStation] = useState("");
  const [openStopStationDropDown, setOpenStopStationDropDown] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [stationList, setStation] = useState([]);
  // SET THE DEPARTURE DATE
  const [departureDate, setDepartureDate] = useState(new Date());
  // STATE TO SELECT PASSENGERS
  const [adultPassengers, setAdultPassengers] = useState(1);
  const [childPassengers, setChildPassengers] = useState(0);
  const [childError, setChildError] = useState(false);

  // TO CHECK IF NO OF CHILD PASSENGERS !> ADULT
  useEffect(() => {
    if (childPassengers < adultPassengers && childPassengers !== 0)
      setChildError(false);

    if (childPassengers > adultPassengers) {
      setChildPassengers(0);
      setChildError(true);
      setTimeout(() => {
        setChildError(false);
      }, 8000);
    }
  }, [childPassengers, adultPassengers]);

  const [error, setError] = useState(false);
  const handleSubmit = async () => {
    if (startStation && stopStation && startStation !== stopStation) {
      let p = new Promise((resolve, reject) =>
        resolve(JSON.parse(localStorage.getItem("KAI")))
      );
      p.then((res) => {
        let KAI = res == null ? {} : res;
        KAI.stationDetails = {
          sourceStation: startStation,
          destnationStation: stopStation,
          date: departureDate,
          adultPassengers: adultPassengers,
          childPassengers: childPassengers,
        };
        localStorage.setItem("KAI", JSON.stringify(KAI)); // store the station details in localStorage
      });
      let req = {
        originStationCode: startStation.stationcode,
        destinationStationCode: stopStation.stationcode,
        departureDate: moment(departureDate).format("YYYY-MM-DD"),
        numberOfAdult: adultPassengers,
        numberOfChildren: childPassengers,
      };
      setLoading(true);
      let res = await getSchedule(req);
      if (res.success) {
        p.then(() => navigate("/stationpricecard"));
        setLoading(false);
      } else {
        showErrorPopUp({
          showPopUp: true,
          message: res.message,
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    async function getStationCallback() {
      setLoading(true);
      localStorage.removeItem("KAI");
      let KAI = {};
      localStorage.setItem("KAI", JSON.stringify(KAI));
      const response = await getStation();
      if (response.success) {
        setStation(response.data);
        setLoading(false);
      } else {
        showErrorPopUp({
          showPopUp: true,
          message: response.message,
        });
        setLoading(false);
      }
    }
    getStationCallback()
  }, []);

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  return (
    <div id="StationSelection" className={"StationSelection"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
        <Header header={header} back={""} step={"1"} />
        <div id="StationSelectionForm" className={"StationSelectionForm"}>
          <div>
            <Typography
              className={"inputLabels"}
              children={"Stasiun Asal"}
              id="StasiunAsal-label"
            />
            <div
              onClick={() =>
                setOpenStartStationDropDown(!openStartStationDropDown)
              }
              className={
                error && !startStation
                  ? "selectStationDropdown-error"
                  : "selectStationDropdown"
              }
              id="StasiunAsal-value"
            >
              <Typography
                children={startStation?.stationname}
                className={"selectInputDataFont"}
                id="StasiunAsal-name"
              />
              <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
            </div>
            <span
              style={{
                display: error && !startStation ? "" : "none",
                color: "#d32f2f",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Pilih stasiun asal terlebih dahulu
            </span>

            <Typography
              className={"inputLabels"}
              children={"Stasiun Tujuan"}
              id="StasiunTujuan-label"
            />
            <div
              onClick={() =>
                setOpenStopStationDropDown(!openStopStationDropDown)
              }
              className={
                error && !stopStation
                  ? "selectStationDropdown-error"
                  : "selectStationDropdown"
              }
              id="StasiunTujuan-value"
            >
              <Typography
                children={stopStation?.stationname}
                className={"selectInputDataFont"}
                id="StasiunTujuan-name"
              />
              <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
            </div>
            <span
              style={{
                display:
                  (error && !stopStation) ||
                  (error && stopStation === startStation)
                    ? ""
                    : "none",
                color: "#d32f2f",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              {error && stopStation === ""
                ? "Pilih stasiun tujuan terlebih dahulu"
                : stopStation !== "" && stopStation === startStation
                ? "Nama stasiun tujuan tidak boleh sama dengan Stasiun Asal"
                : ""}
            </span>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: "1" }}>
                {" "}
                <Typography
                  className={"inputLabels"}
                  children={"Tanggal Keberangkatan"}
                  id="TanggalKeberangkatan-label"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={id}>
                  <MobileDatePicker
                    InputProps={{ style: { borderRadius: "8px" } }}
                    disablePast
                    DialogProps={{ className: classes.selectDepartureDate }}
                    value={departureDate}
                    cancelText={""}
                    onChange={(date) => setDepartureDate(date)}
                    renderInput={(params) => {
                      params.inputProps.value = moment(
                        params.inputProps.value,
                        "DD/MM/YYYY"
                      ).format("ddd, DD MMM YYYY");
                      return (
                        <TextField
                          fullWidth
                          className={classes.departureDateTextField}
                          {...params}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {/* Adult Passangers */}
            <Typography
              className={"inputLabels"}
              children={"Penumpang Dewasa (>=3th)"}
              id="PenumpangDewasa-label"
            />
            <Select
              value={adultPassengers}
              onChange={(e) => setAdultPassengers(e.target.value)}
              IconComponent={() => (
                <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
              )}
              className={"selectInputs"}
              fullWidth
              id="PenumpangDewasa-value"
            >
              <MenuItem children={"1 Orang"} value={1} />
              <MenuItem children={"2 Orang"} value={2} />
              <MenuItem children={"3 Orang"} value={3} />
              <MenuItem children={"4 Orang"} value={4} />
            </Select>
            <Typography
              className={"inputLabels"}
              children={"Penumpang Bayi (<3th)"}
              id="PenumpangBayi-label"
            />
            <Select
              error={childError}
              value={childPassengers}
              onChange={(e) => setChildPassengers(e.target.value)}
              IconComponent={() => (
                <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
              )}
              className={"selectInputs"}
              fullWidth
              id="PenumpangBayi-value"
            >
              <MenuItem children={"0 Orang"} value={0} />
              <MenuItem children={"1 Orang"} value={1} />
              <MenuItem children={"2 Orang"} value={2} />
              <MenuItem children={"3 Orang"} value={3} />
              <MenuItem children={"4 Orang"} value={4} />
            </Select>
            <span
              style={{
                display: childError ? "" : "none",
                color: "#d32f2f",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              Penumpang bayi dilarang melebihi jumlah penumpang dewasa
            </span>
          </div>

          <div style={{ marginTop: "30px" }}>
            <Typography className="terms">
              Produk atau jasa ini disediakan oleh Kereta Api Indonesia dan
              sepenuhnya menjadi tanggung jawab Kereta Api Indonesia. Baca
              Syarat dan Ketentuan layanan{" "}
              <a
                style={{ color: "#007DFE", textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
                href = "https://bmri.id/sukhatncxkai"
              >
                di sini.
              </a>
            </Typography>
          </div>
          <div>
            <Button
              children={"Cari Kereta Api"}
              fullWidth
              variant={"contained"}
              className={"submitOrangeButton"}
              onClick={() => handleSubmit()}
              id="CariKeretaApi"
            />
          </div>
        </div>
        <Footer />
      </div>
      <SelectStationDropDown
        list={stationList}
        slideOpen={openStartStationDropDown}
        setOpenStationDropDown={setOpenStartStationDropDown}
        setStationName={setStartStation}
        title={"Stasiun Awal"}
      />
      <SelectStationDropDown
        list={stationList}
        slideOpen={openStopStationDropDown}
        setOpenStationDropDown={setOpenStopStationDropDown}
        setStationName={setStopStation}
        title={"Stasiun Tujuan"}
      />
    </div>
  );
};

export default StationSelection;

const useStyle = makeStyles({
  selectDepartureDate: {
    // giv a line after the date displayed at top in Date Picker
    "& .PrivatePickersToolbar-root": {
      borderBottom: "1px solid #DDD !important",
    },
    // removed the edit button which changed the view of the date picker
    "& .PrivateDatePickerToolbar-penIcon": {
      display: "none",
    },
    // change color and background of the CANCEL and OK button
    "& .MuiButton-root": {
      background: "#DD6B00",
      color: "#FFF",
      "&:hover": {
        background: "#C96100",
      },
    },
    // background color applied to date when selected and also when seledted and hover on it
    "& .Mui-selected": {
      backgroundColor: "#007DFE !important",
      "&:hover": {
        backgroundColor: "#0671DF !important",
      },
    },
  },
  departureDateTextField: {
    // style APPLIED to TEXTFIELD
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px !important",
      fontSize: "14px !important",
    },
  },
});
